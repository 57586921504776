import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import type { Messaging } from "firebase/messaging";
import { useLocalStorage } from "@vueuse/core";
import { requestEnrollDevice, HttpCodes } from "@magnit/layer-api/api";
import { storage } from "~/utils/consts";
import serviceWorkerInit from "~/sw";

interface IPushStatus {
  sendToken: "initial" | "pending" | "success" | "error";
  permissions: "initial" | "pending" | "success" | "error";
}

export const fbaseConfig = {
  apiKey: "AIzaSyA8MZ5828RVcFW2p9t_EBRhmIeyUkn5JuQ",
  authDomain: "magnitandroid-2c90e.firebaseapp.com",
  databaseURL: "https://magnitandroid-2c90e.firebaseio.com",
  projectId: "magnitandroid-2c90e",
  storageBucket: "magnitandroid-2c90e.appspot.com",
  messagingSenderId: "491612239134",
  appId: "1:491612239134:web:0d2bff77d5d0654be3450f",
  measurementId: "G-546WDFVLKF",
};

const VAPID_KEY =
  "BPDAwhzHAmkzDS4Z-xsisxkn71-91BxVLiPQDfsd00JUlfx-bVqPzxl0zvmnhXcsaN97MgdXDE48FNDvnoZ9ZWQ";

const CHECK_PUSH_TOKEN_INTERVAL_MS = 60 * 60 * 24 * 7 * 1000;

export const usePushStore = defineStore("push", () => {
  const userStore = useUserStore();
  const { send } = useAnalytics();
  const { warning } = useNotification();

  const fbm = ref<Messaging>();
  const status = reactive<IPushStatus>({
    sendToken: "initial",
    permissions: "initial",
  });

  const savedSubscription = useLocalStorage<string>(storage.pushSubscription, "");
  const savedSubscriptionUpdated = useLocalStorage<string>(storage.pushSubscriptionUpdated, "");
  const lastPermissionsState = useLocalStorage(storage.lastPushPermissionState, "");

  watch(
    () => userStore.permissions.isPushPermitted,
    (next) => {
      if (userStore.status.permissions === "success") {
        if (next) {
          getFBMApi();
        }
      }
    },
  );

  async function enrollDevice(token: string, hasPermission: boolean) {
    status.sendToken = "pending";

    const { data, error } = await requestEnrollDevice(token, hasPermission);

    if (data.value !== null) {
      status.sendToken = "success";
      if (hasPermission) {
        savedSubscription.value = token;
        await userStore.updatePermissions(true, "isPushPermitted");
      }
    }

    if (error.value) {
      if (error.value?.statusCode && error.value.statusCode > HttpCodes.Error4xx) {
        status.sendToken = "error";

        warning({
          text: "Не удалось включить уведомления. Попробуйте ещё раз",
        });
      }
    }
  }

  const getFBMApi = (): Messaging => {
    if (fbm.value) {
      return fbm.value;
    }
    const fb = initializeApp(fbaseConfig);
    fbm.value = getMessaging(fb);
    return fbm.value;
  };

  const requestPermission = async (auth?: boolean, refresh?: boolean) => {
    try {
      const permission = refresh ? Notification.permission : await Notification.requestPermission();
      if (permission === "granted") {
        send(
          auth
            ? "App:Host:Auth:Push:Permission:Check:Granted"
            : "App:Host:Profile:Push:Permission:Check:Granted",
        );
      } else {
        send(
          auth
            ? "App:Host:Auth:Push:Permission:Check:Declined"
            : "App:Host:Profile:Push:Permission:Check:Declined",
        );
      }

      const registration = await navigator.serviceWorker.getRegistration();
      if (!registration) {
        await serviceWorkerInit();
      }
      const api = getFBMApi();
      status.permissions = "success";

      const currentToken =
        permission === "granted"
          ? await getToken(api, {
            serviceWorkerRegistration: registration,
            vapidKey: VAPID_KEY,
          })
          : savedSubscription.value;

      if (currentToken) {
        try {
          const updatedToken = currentToken !== savedSubscription.value;
          if (updatedToken || permission !== lastPermissionsState.value) {
            await enrollDevice(currentToken, permission === "granted");
            lastPermissionsState.value = permission;
          }
          if (updatedToken || !savedSubscriptionUpdated.value) {
            savedSubscriptionUpdated.value = String(Date.now());
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log("An error occurred while retrieving token. ", err);
        }
      } else {
        // Show permission request.
        // eslint-disable-next-line no-console
        console.log("No registration token available. Request permission to generate one.");
      }
    } catch (e) {
      status.permissions = "error";
      send(auth ? "App:Auth:Push:Failure:View" : "App:Profile:Push:Failure:View");
      warning({
        text: "Не удалось включить уведомления. Попробуйте ещё раз",
      });
    }
  };

  const updateToken = async () => {
    if (typeof window === "undefined") {
      return;
    }
    if (
      savedSubscription.value &&
      (!savedSubscriptionUpdated.value ||
        Date.now() - CHECK_PUSH_TOKEN_INTERVAL_MS > Number(savedSubscriptionUpdated.value) ||
        Notification.permission !== lastPermissionsState.value)
    ) {
      await requestPermission(false, true);
    }
  };

  return {
    status,
    hasToken: computed(() => Boolean(savedSubscription.value)),
    requestPermission,
    updateToken,
    enrollDevice,
  };
});
